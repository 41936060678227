import React, { useEffect, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import SEO from "./seo";
// require('../assets/js/vendor/vendor.min.js')
// require('../assets/js/plugins/plugins.js')
// require('../assets/js/main.js')
import Header from "./Header";
import Footer from "./Footer";
import $ from "jquery";
import MenuPanel from "./MenuPanel";
// if (typeof window !== "undefined") {
//   // eslint-disable-next-line global-require
//   require("smooth-scroll")('a[href*="#"]')
// }

const Layout = ({ location, title, children }) => {
  // const [isLoading, setIsLoading] = useState(true);
  const scriptElements = [];
  useEffect(() => {
    const scriptPaths = [
      // "/assets/canvas/js/jquery.js",
      // "/assets/canvas/js/plugins.min.js",
      // "/assets/canvas/js/functions.js",
      // "/assets/canvas/demos/photographer/js/hover3d.js",
      // "/assets/canvas/demos/photographer/js/menu-easing.js",
      "/asset/js/jquery-3.5.1.min.js",
      "/asset/js/jquery.easing.1.3.js",
      "/asset/js/bootstrap.bundle.min.js",
      "/asset/js/SmoothScroll.js",
      "/asset/js/jquery.scrollTo.min.js",
      "/asset/js/jquery.localScroll.min.js",
      "/asset/js/jquery.viewport.mini.js",
      "/asset/js/jquery.countTo.js",
      "/asset/js/jquery.appear.js",
      "/asset/js/jquery.parallax-1.1.3.js",
      "/asset/js/jquery.fitvids.js",
      "/asset/js/owl.carousel.min.js",
      "/asset/js/isotope.pkgd.min.js",
      "/asset/js/imagesloaded.pkgd.min.js",
      "/asset/js/jquery.magnific-popup.min.js",
      "/asset/js/masonry.pkgd.min.js",
      "/asset/js/jquery.lazyload.min.js",
      "/asset/js/wow.min.js",
      "/asset/js/morphext.js",
      "/asset/js/typed.min.js",
      "/asset/js/contact-form.js",
      "/asset/js/jquery.ajaxchimp.min.js",
      "/asset/js/objectFitPolyfill.min.js",
      "/asset/js/splitting.min.js",
      "/asset/js/all.js",
      "/asset/js/jquery.mb.YTPlayer.js"
    ];

    $(() => {
      scriptPaths.forEach((src, index) => {
        let script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        script.className = `main-script-${index}`;
        document
          .querySelectorAll(`.main-script-${index}`)
          .forEach(function (a) {
            a.remove();
          });

        scriptElements.push(script);

        if (index == 0) {
          document.body.appendChild(script);
        } else {
          scriptElements[index - 1].onload = function () {
            console.log("onload fired for script " + index);
            document.body.appendChild(script);
          };
        }

        // if(scriptElements.length == scriptPaths.length) {
        //   console.log('check me out', scriptElements)
        //   scriptElements[scriptElements.length - 1].onload = () => {
        //     console.log("set laoding to false");
        //     setIsLoading(false);
        //   };
        // }
      });
      console.log("WHERE IS JQUERY", window.jQuery, window);
      // scriptElements[scriptElements.length - 1].onload = () => {
      //   setIsLoading(false);
      // };
    });
  }, []);

  // console.log('check me out', scriptElements)
  // if(scriptElements.length != 0)
  //   scriptElements[scriptElements.length - 1].onload = () => {
  //     setIsLoading(false);
  //   };

  // const data = useStaticQuery(graphql`
  //   query LayoutQuery {
  //     markdownRemark(frontmatter: { id: { eq: "home" } }) {
  //       frontmatter {
  //         logo
  //         id
  //       }
  //     }
  //   }
  // `)
  // const layout = data.markdownRemark.frontmatter.layout

  const data = useStaticQuery(graphql`
    query ContactQuery {
      site {
        siteMetadata {
          title
        }
      }
      markdownRemark(frontmatter: { id: { eq: "contact" } }) {
        frontmatter {
          zomatoLink
          id
          instagramLink
          whatsappLink
          whatsappNumber
          companyName
          address
          phoneNumber
        }
      }
      layout: markdownRemark(frontmatter: { id: { eq: "home" } }) {
        frontmatter {
          logo
          id
        }
      }
    }
  `);
  console.log("data", data);

  const rootPath = `${__PATH_PREFIX__}/`;

  // if (isLoading)
  //   return (
  //     <div className="container" style={{
  //       width: "100vw",
  //       height: "100vh",
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center"
  //     }}>
  //       <img style={{height: "150px"}} src="/azzet/img/loading-ripple-min.gif" />
  //     </div>
  //   );
  // else
  return (
    <>
      <SEO title="Hux Grooming"/>
        {children}
    </>
  );
};

export default Layout;
